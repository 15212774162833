import React from "react"
import { Typography, Card } from "antd"
import { Animated } from "react-animated-css"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/Layout"
import TrustedBy from "../../components/TrustedBy"
import FeaturedWork from "../../components/WEB_FeaturedWork"
import DevelopmentProcess from "../../components/WEB_DevelopmentProcess"
import TechStack from "../../components/WEB_TechStack"
import SideForm from "../../components/SideForm"
import "../../styles/services.scss"
import "../../styles/animate.css"
import behindImageSmall from "../../images/Group 495@2x.png"
import custom_webapp_img from '../../images/Web_Dev-01.png'
import Architecture_img from '../../images/Architecture-01.png'
import backend_img from '../../images/backend-01.png'
import deployment_img from '../../images/deployment-01.png'
import ecommerce_img from '../../images/ecommerce-01.png'
import frontend_img from '../../images/frontend-01.png'
import UIUX_img from '../../images/UIUX-01.png'
import unit_testing_img from '../../images/unit_testing-01.png'
import ServiceCard from "../../components/ServiceCard"
import ServiceStepsProcess from "../../components/ServiceStepsProcess"
import IconsAdded from "../../components/IconsAdded"
const { Title } = Typography

const Index = ({ data }) => {
  const backgroundImage = data.bgImage.childImageSharp.fluid.src
  return (
    <Layout className="services-page-container" data={data} 
    pageTitle={"Visiomate - Web Development Services"}
    descrip = {"From design to deployment, we handle every aspect of web development. Partner with us for innovative, scalable, and engaging web solutions."}>
      <div className="top-section"
        style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="logo">
          <Link to="/">
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
          </Link>
        </div>
        <div className="box-wrapper text-and-form-container">
          <div className="information">
            <Animated
              animationIn="fadeInUp"
              animationInDuration={500}
              animationInDelay={300}
              isVisible={true}
            >
              <Title>Web based enterprise level applications</Title>
              <br />
              <p>
                We provide state-of-the-art web-based solutions to help our
                customers achieve building highly accessible applications.{" "}
                <br />
                <br />
                Our focus is to enable our customers with the latest
                technologies and trends to maximize their productivity,
                collaboration, and availability.
              </p>
              <TrustedBy showHeading={false} />
            </Animated>
          </div>
          <Animated
            animationIn="fadeInDown"
            animationInDuration={800}
            animationInDelay={500}
            isVisible={true}
            className="form"
          >
            <SideForm />
          </Animated>
        </div>
      </div>
      <div className="services-description-section">
      <div className="service-heading-and-detail">
        <Title>Web Development Services</Title>
      </div>
      <div className="service-cards-wrapper">
        <ServiceCard img_src={custom_webapp_img} img_alt={'custom_webapp_img'} card_heading={'Custom Web Apps'} card_para={'Design custom web applications that cater to your business requirements boosting productivity and user interaction with functionalities and smooth incorporation into current systems.'}/>
        <ServiceCard img_src={ecommerce_img} img_alt={'ecommerce_img'} card_heading={'E-commerce Websites'} card_para={'Create platforms that aim to boost sales and improve the shopping experience, for customers. We provide payment options, user friendly interfaces and mobile optimization, for a shopping experience.'}/>
        <ServiceCard img_src={UIUX_img} img_alt={'UIUX_img'} card_heading={'UI/UX'} card_para={'Improve user happiness with our UI and UX design services that prioritize crafting user interfaces and captivating experiences to help users engage effortlessly while ensuring your digital products are both useful and visually attractive.'}/>
        <ServiceCard img_src={Architecture_img} img_alt={'Architecture_img'} card_heading={'Architecture & Design'} card_para={'Create an effective framework, for your projects with our design services that focus on performance and security while ensuring maintenance, for your applications to succeed in a competitive environment.'}/>
        <ServiceCard img_src={frontend_img} img_alt={'frontend_img'} card_heading={'Frontend Development'} card_para={'Turn your design ideas into user interfaces using our frontend development services that leverage cutting edge technologies to produce engaging web applications ensuring a seamless user experience, on any device.'}/>
        <ServiceCard img_src={backend_img} img_alt={'backend_img'} card_heading={'Backend Development'} card_para={'Enhance your applications with server side solutions that effectively manage data and secure your system while seamlessly integrating with frontend elements through our development services.'}/>
        <ServiceCard img_src={unit_testing_img} img_alt={'unit_testing_img'} card_heading={'Unit Testing'} card_para={'Ensure the quality of your software with our thorough unit testing services that meticulously check each component of your application for bugs at a stage of development to enhance reliability and performance.'}/>
        <ServiceCard img_src={deployment_img} img_alt={'deployment_img'} card_heading={'Deployment & Support'} card_para={'A critical components of the software development lifecycle, ensuring that applications are successfully launched and maintained for optimal performance.'}/>
        <ServiceCard card_heading={'Let connect, book a free consultancy session with our experts'} card_button={"Free Consultancy"} numOfCard='odd'/>
      </div>
        <div className="behindimagediv">
            <img
              className="behindImageSmall"
              src={behindImageSmall}
              alt="yellow-triangle"
            />
            <img className="behindImageLarg" src={behindImageSmall} alt="yellow-triangle" />
        </div>
      </div>
      <div className="featured-projects position-relative">
        <FeaturedWork data={data} />
        <IconsAdded/>
      </div>
      <DevelopmentProcess title='Our Web App Development process' >
        <ServiceStepsProcess process_heading='Inception & Elicitation' process_para="Talk to the customer Listen to what the customer has to say Brainstorm and prepare project backlog." process_step_number="1" process_indentify="odd" />
        <ServiceStepsProcess process_heading='Plan sprint' process_para="The team reviews the product backlog and selects items they believe can be completed within the sprint timeframe." process_step_number="2" />
        <ServiceStepsProcess process_heading='Execute Sprint' process_para="The team focuses on completing the items in the sprint backlog, which consists of tasks selected from the product backlog based on priority and team capacity." process_step_number="3" process_indentify="odd" iconShow="dark"/>
        <ServiceStepsProcess process_heading='Review and Deliver' process_para="During this step, the development team showcases the work completed, demonstrating new features and functionalities to the customer." process_step_number="4" iconShow="dark" />
        <ServiceStepsProcess process_heading='Support & maintenance' process_para="A critical phases in the software development lifecycle, ensuring that applications are successfully launched and maintained for optimal performance." process_step_number="5" process_indentify="odd" />
      </DevelopmentProcess>
      <TechStack />
    </Layout>
  )
}

export default Index

export const webDevelopmentQuery = graphql`
  query webDevelopmentQuery {
    FarmManagementSystem: file(
      relativePath: { eq: "FarmManagementSystem/pf.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ISOComplianceManagement: file(
      relativePath: { eq: "ISOComplianceManagement/iso.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    TenderAssetRecordManagement: file(
      relativePath: { eq: "TenderAssetRecordManagement/tender.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ViztosPos: file(relativePath: { eq: "ViztosPos/vistos_image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo1: file(relativePath: { eq: "Odoo/odoo1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo2: file(relativePath: { eq: "Odoo/odoo2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo3: file(relativePath: { eq: "Odoo/odoo3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Custom: file(relativePath: { eq: "Custom/CustDevelopment.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365: file(relativePath: { eq: "D365/D365.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365_2: file(relativePath: { eq: "D365/D365_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Support: file(relativePath: { eq: "safty.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis: file(relativePath: { eq: "analysis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis2: file(relativePath: { eq: "analysis2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Development: file(relativePath: { eq: "development.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Connect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Data: file(relativePath: { eq: "data.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage: file(relativePath: { eq: "Mask_group8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    EMS: file(relativePath: { eq: "EMS/ems_side.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Viztos: file(relativePath: { eq: "ViztosPos/viztos_side.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Tender: file(relativePath: { eq: "TenderAssetRecordManagement/trn_side.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`