import React from "react"
import { Typography, Card } from "antd"
import { IconContext } from "react-icons"
import { FaReact, FaSass, FaJsSquare, FaPython, FaCss3, FaAngular, FaBootstrap, FaHtml5, FaNodeJs, } from "react-icons/fa"
import { SiRedux, SiPostgresql, SiAntdesign, SiNginx, SiCsharp, SiMicrosoftsqlserver, SiMysql, SiSqlite, SiLinux, SiAmazonaws, SiMicrosoftazure, SiApache, SiDocker } from "react-icons/si"
import { GrGraphQl } from "react-icons/gr";
import "../styles/utilities.scss"
const { Title } = Typography

const TechStack = () => {
  return (
    <div className="tech-stack">
      <div className="heading">
        <Title>Web Development - Technology Stack</Title>
      </div>
      <div className="info-container">
        <div className="technologies_logos">
          <div className="language">
            <Title level={3}>Language</Title>
            <IconContext.Provider value={{ className: "service-tech-icons" }}>
              <div>
                <FaReact />
                <FaAngular />
                <FaNodeJs />
                <FaJsSquare />
                <FaSass />
                <SiRedux />
                <FaPython />
              </div>
            </IconContext.Provider>
            <div className="second-row">
              <IconContext.Provider value={{ className: "service-tech-icons" }}>
                <div>
                  <FaHtml5 />
                  <FaCss3 />
                  <FaBootstrap />
                  <SiCsharp />
                  <GrGraphQl />
                  <SiAntdesign />
                </div>
              </IconContext.Provider>
            </div>
          </div>
          <div className="database">
            <Title level={3}>Database</Title>
            <IconContext.Provider value={{ className: "service-tech-icons" }}>
              <div>
                <SiMicrosoftsqlserver />
                <SiPostgresql />
                <SiMysql />
                <SiSqlite />
              </div>
            </IconContext.Provider>
          </div>
          <div className="framework">
            <Title level={3}>Infrastructure</Title>
            <IconContext.Provider value={{ className: "service-tech-icons" }}>
              <div>
                <SiAmazonaws />
                <SiMicrosoftazure />
                <SiLinux />
                <SiNginx />
                <SiApache />
                <SiDocker />
              </div>
            </IconContext.Provider>
          </div>
        </div>
        <div style={{ height: "100%" }}>
          <Card style={{ width: "100%", height: "100%" }}>
            <Title level={3}>We have the capability of the most popular programming languages, databases, and infrastructures.</Title>
            <p> We use the most popular programming languages to build web-based solutions. We are always ready to adopt new web technologies,
              to produce futuristic solutions. Currently, we use React JS, Angular, NodeJs, Redux, Python, Html5, Css3, Bootstrap, Csharp, and GraphQl.</p>
            <p>
              Databases are used to store the data in a centralized location. Choosing the right database is vital for the successful run of the application.
              Currently, we use Microsoft SQL Server, Postgresql, Mysql and SQLite for most projects.
            </p>
            <p>
              When the application is developed and ready to be deployed for users to use, choosing the suitable infrastructure is important considering the maintenance efforts.
              Currently, we use Amazon aws, Microsofta zure, Linux, Nginx, Nginx and Docker for most projects.
            </p>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default TechStack
